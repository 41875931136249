.breadcrumbNav {
    margin-top: 8px;
}

.breadcrumbMain .MuiBreadcrumbs-separator {
    margin: 0px 4px !important;
}

.menuLinkBack {
    font-style: italic !important;
    font-weight: 500 !important;
    font-size: 16px !important;
    line-height: 24px !important;
    color: #006A77 !important;
    text-decoration-line: none !important;
    opacity: 0.64 !important;
}

.menuTextUser {
    font-style: italic !important;
    font-weight: 500 !important;
    font-size: 16px !important;
    line-height: 24px !important;
    color: #006A77 !important;
    text-decoration-line: none !important
}

.infocard {
    background: #F8F6F1;
    border-radius: 12px;
    /* max-height: 106px; */
    min-height: 78px;
    min-width: 220px;
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 0px;
}

.infocardContent {
    width: 100%;
    padding-left: 24px;
}

.profileName {
    max-width: 170px;
}

@media only screen and (max-width: 1460px) {

    .infocard {
        margin-bottom: 0px;
    }

    .profileName {
        max-width: 100%;
    }

    .infocardContainer {
        padding-right: 0;
    }
}

.subTitleInfoCard {
    color: #1C2324;
    opacity: 0.64;
    font-style: normal;
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 22px !important;
}

.mainTitleInfoCard {
    margin-top: 4px !important;
    font-style: normal;
    font-weight: 600 !important;
    font-size: 16px !important;
    line-height: 24px !important;
    text-transform: uppercase;
    color: #1C2324;
}

.leftSubdiv {
    margin: 4px !important;
}

.countTxtInfoCard{
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 24px !important;
    line-height: 28px !important;
    color: #006A77 !important;
    display: flex !important;
    align-items: center;
    justify-content: center;
    padding: 6px 16px 6px 12px;
    min-width: 32px;
}

.expandArrowInfoCard{
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 24px !important;
    line-height: 36px !important;
    color: #1C2324 !important;
    display: flex !important;
    align-items: center;
    justify-content: center;
    padding: 6px 16px 6px 12px;
    min-width: 32px;
}


.feCardsParent .infocardContainer:first-child {
    padding: 12px 12px 12px 0;
}

.feCardsParent .infocardContainer:not(:first-child):not(:last-child) {
    padding: 12px 12px 12px 0;
}

.feCardsParent .infocardContainer:last-child {
    padding: 12px 0 12px;  /* Value for the last child */
}

@media (min-width: 961px) and (max-width: 1279px) {
    .feCardsParent .infocardContainer:nth-child(2) {
        padding: 12px 0 !important;
    }
    .feCardsParent .infocardContainer:not(:first-child):not(:last-child):not(:nth-child(2)) {
        padding: 12px 12px 12px 0 !important;
    }
}

@media (max-width: 960px) {
    .feCardsParent .infocardContainer {
        padding: 12px 0 !important;
    }
    .feFarmerSearch {
        justify-content: flex-start !important;
    }
    .feActionButtons {
        margin-top: 0px !important;
    }
}
