::placeholder {
    color: #1c2324 !important;
    opacity: 0.42 !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 24px !important;
}


#demo-customized-select:focus {
    background-color: transparent;
}

.displayItem {
    padding-inline: 2rem;
    display: flex;
    gap: 1rem;
}

.primaryRootBox {
    display: flex;
    gap: 1rem;
}

.widthItem {
    width: 50%
}

.primaryRootBoxAlt {
    width: 50%;
    display: flex;
    gap: 1rem;
}

.secondaryInputDetails {
    display: flex;
    gap: 1rem;
    align-items: center;
    margin-top: 16px;
}

.secondaryInputDetailsAlt {
    width: 50%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;

}

@media screen and (max-width:600px) {

    .activityErrorMessageModal {
        width: 90% !important;
    }

    .displayItem {
        flex-direction: column;
        padding-inline: 1.4rem;
    }

    .primaryRootBoxParent {
        padding-inline: 1.4rem !important;
    }

    .widthItem {
        width: 100%;
    }

    .calendarSecondaryRootContainer {
        padding-inline: 0 !important;
    }

    .calendarFieldLabel {
        font-size: 0.74rem !important;
    }

    .primaryRootBox {
        flex-direction: column;
    }

    .primaryRootBoxAlt {
        width: 100%;
    }

    .secondaryInputDetails {
        flex-direction: column;
    }

    .secondaryInputDetailsAlt {
        width: 100%;
        flex-direction: column;
        gap: 0;

    }

    .customFullWidth .languageTabClass {
        font-size: 0.9rem !important;
    }

    .customFullWidth .languageTabClass:nth-child(2) {
        padding-right: 6px;
    }
}

.calendarGridWrapper {
    padding-inline-start: 1.7rem
}

@media screen and (max-width:360px) {
    .headingResponsive {
        font-size: 24px !important;
    }
}

@media screen and (max-width:461px) {
    .calendarGridWrapper {
        padding-inline-start: 0rem !important
    }

    .headingResponsive {
        margin-inline-start: 0 !important;
    }
}

.phaseBoxStyleDiv {
    width: 646px !important;
    max-width: 646px !important;
    overflow: auto !important;
}

@media (min-width:431px) and (max-width:767px) {
    .daysContainerBGWrapper {
        left: -5.5% !important;
    }
}

@media screen and (max-width:440px) {
    .daysContainerBGWrapper {
        left: -8% !important;
    }
}

@media screen and (max-width:800px) {
    .modalContentWrapper {
        width: 90% !important;
    }
}

/* NEW CSS START*/
@media (min-width: 1051px) and (max-width:1080px) {
    .globalCalendarContainerResponsive {
        width: 725px !important;
    }
}

@media (min-width: 1011px) and (max-width:1050px) {
    .globalCalendarContainerResponsive {
        width: 700px !important;
    }

    .phaseBoxStyleDiv {
        max-width: 625px !important;
    }
}

@media (min-width: 960px) and (max-width:1010px) {
    .globalCalendarContainerResponsive {
        width: 680px !important;
    }

    .phaseBoxStyleDiv {
        max-width: 605px !important;
    }
}

@media (min-width: 821px) and (max-width: 840px) {
    .globalCalendarContainerResponsive {
        width: 725px !important;
    }
}

@media (min-width: 801px) and (max-width: 820px) {
    .globalCalendarContainerResponsive {
        width: 710px !important;
    }

    .phaseBoxStyleDiv {
        max-width: 635px !important;
    }
}

@media (min-width: 781px) and (max-width: 800px) {
    .globalCalendarContainerResponsive {
        width: 690px !important;
    }

    .phaseBoxStyleDiv {
        max-width: 615px !important;
    }
}

@media (min-width: 768px) and (max-width: 780px) {
    .globalCalendarContainerResponsive {
        width: 670px !important;
    }

    .phaseBoxStyleDiv {
        max-width: 595px !important;
    }
}

@media (min-width: 751px) and (max-width: 767px) {
    .globalCalendarContainerResponsive {
        width: 650px !important;
    }

    .phaseBoxStyleDiv {
        max-width: 575px !important;
    }
}

@media (min-width: 721px) and (max-width: 750px) {
    .globalCalendarContainerResponsive {
        width: 630px !important;
    }

    .phaseBoxStyleDiv {
        max-width: 555px !important;
    }
}

@media (min-width: 701px) and (max-width: 720px) {
    .globalCalendarContainerResponsive {
        width: 610px !important;
    }

    .phaseBoxStyleDiv {
        max-width: 535px !important;
    }
}

@media (min-width: 671px) and (max-width: 700px) {
    .globalCalendarContainerResponsive {
        width: 580px !important;
    }

    .phaseBoxStyleDiv {
        max-width: 505px !important;
    }
}

@media (min-width: 651px) and (max-width: 670px) {
    .globalCalendarContainerResponsive {
        width: 560px !important;
    }

    .phaseBoxStyleDiv {
        max-width: 485px !important;
    }
}

@media (min-width: 631px) and (max-width: 650px) {
    .globalCalendarContainerResponsive {
        width: 540px !important;
    }

    .phaseBoxStyleDiv {
        max-width: 465px !important;
    }
}

@media (min-width: 601px) and (max-width: 630px) {
    .globalCalendarContainerResponsive {
        width: 510px !important;
    }

    .phaseBoxStyleDiv {
        max-width: 445px !important;
    }
}

@media (min-width: 581px) and (max-width: 600px) {
    .globalCalendarContainerResponsive {
        width: 490px !important;
    }

    .phaseBoxStyleDiv {
        max-width: 415px !important;
    }
}

@media (min-width: 561px) and (max-width: 580px) {
    .globalCalendarContainerResponsive {
        width: 470px !important;
    }

    .phaseBoxStyleDiv {
        max-width: 400px !important;
    }
}

@media (min-width: 531px) and (max-width: 560px) {
    .globalCalendarContainerResponsive {
        width: 440px !important;
    }

    .phaseBoxStyleDiv {
        max-width: 385px !important;
    }
}

@media (min-width: 501px) and (max-width:530px) {
    .globalCalendarContainerResponsive {
        width: 410px !important;
    }

    .phaseBoxStyleDiv {
        max-width: 360px !important;
    }
}

@media (min-width: 441px) and (max-width:500px) {
    .globalCalendarContainerResponsive {
        width: 370px !important;
    }

    .phaseBoxStyleDiv {
        max-width: 320px !important;
    }
}

@media (min-width: 411px) and (max-width:440px) {
    .globalCalendarContainerResponsive {
        width: 350px !important;
    }

    .phaseBoxStyleDiv {
        max-width: 290px !important;
    }
}

@media (min-width: 391px) and (max-width:410px) {
    .globalCalendarContainerResponsive {
        width: 330px !important;
    }

    .phaseBoxStyleDiv {
        max-width: 270px !important;
    }
}

@media (min-width: 361px) and (max-width:390px) {
    .globalCalendarContainerResponsive {
        width: 300px !important;
    }

    .phaseBoxStyleDiv {
        max-width: 250px !important;
    }
}

@media (min-width: 341px) and (max-width:360px) {
    .globalCalendarContainerResponsive {
        width: 280px !important;
    }

    .phaseBoxStyleDiv {
        max-width: 230px !important;
    }
}

@media (min-width: 321px) and (max-width:340px) {
    .globalCalendarContainerResponsive {
        width: 270px !important;
    }

    .phaseBoxStyleDiv {
        max-width: 210px !important;
    }
}

@media screen and (max-width:320px) {
    .globalCalendarContainerResponsive {
        width: 250px !important;
    }

    .phaseBoxStyleDiv {
        max-width: 200px !important;
    }
}

/* NEW CSS END*/