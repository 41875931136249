/* SUPER ADMIN PROFILE CSS STARTS */

.main-profile-section-wrapper {
    width: calc(100% + 24px) !important;
    max-width: calc(100% + 24px) !important;
}

.profile-card-right-div-top {
    margin-bottom: 24px !important;
}

.profile-card-right-div-bottom {
    width: calc(100% + 24px) !important;
    max-width: calc(100% + 24px) !important;
    margin: 0 -12px !important;
}

.sub-card-right-bottom-Wrapper {
    padding: 0 12px !important;
}

.sub-card-right-bottom {
    background-color: #F8F6F1 !important;
    border-radius: 12px !important;
    padding: 14px 24px !important;
}

.profile-card-left-div {
    background-color: #F8F6F1 !important;
    border-radius: 12px !important;
    padding: 24px !important;
}

.sub-card-right-top {
    background-color: #F8F6F1 !important;
    border-radius: 12px !important;
    padding: 24px !important;
}

.countNumbertxt {
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 24px !important;
    line-height: 36px !important;
    color: #006A77 !important;
    display: flex !important;
    align-items: center;
    justify-content: center;
    padding: 6px 2px 6px 27px;
    min-width: 32px;
}

.countNumbertxt2 {
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 24px !important;
    line-height: 36px !important;
    color: #006A77 !important;
    display: flex !important;
    align-items: center;
    justify-content: center;
    padding: 6px 0px 6px 12px;
    min-width: 32px;
}

.avatarTypographyContact {

    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 22px !important;
    text-transform: uppercase !important;
    color: #1C2324 !important;
    margin-left: 4px !important;
}

.ProfileLabelName {
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 22px !important;
    opacity: 0.64;
    margin-right: 8px !important;
}

.ProfileLabelValue {
    color: #1C2324 !important;
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: 24px !important;
}

.changeRoleSelectMenu>.MuiSelect-selectMenu {
    color: #1C2324 !important;
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: 24px !important;
    text-transform: uppercase !important;
}

.changeRoleSelectMenu>.MuiSelect-select:focus {
    background-color: transparent !important;
}

.changeRoleSelectMenu .MuiSelect-icon {
    display: none !important;
}

.changeRoleSelectMenu .MuiSelect-root {
    /* background-image: url('./../assets/ArrowDown.png'); */
    background-size: 20px;
    background-repeat: no-repeat;
    background-position: calc(100% - 5px) center;
}

.changeRoleSelectMenu .MuiSelect-root~.MuiSelect-iconOpen {
    background-image: url('./../assets/ArrowUp.png');
}

.changeRoleSelectMenu .MuiSelect-select.MuiSelect-select {
    padding-right: 30px !important;
}

.margin-top16 {
    margin-top: 16px !important;
}

@media only screen and (max-width: 1279px) {

    .profileIconWrapper {
        text-align: center;
    }

    .profileNameWrapper {
        text-align: center;
    }

    .sub-card-right-bottom-Wrapper-right {
        margin-top: 24px !important;
    }
}


@media (max-width: 960px) {
    .saProfileImage {
        justify-content: center !important;
    }
}


/* SUPER ADMIN PROFILE CSS ENDS */

/* ADD USER MODAL CSS STARTS*/
.add-user-modal-title-txt {
    color: #1C2324;
    font-size: 24px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: 36px !important;
}

/* ADD USER MODAL CSS STARTS*/

/* ADMIN TABLE CSS STARTS */
.list-user-title {
    color: #1C2324 !important;
    font-size: 24px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: 36px !important;
}

.table-grid-div .MuiDataGrid-root {
    border-radius: 0px !important;
    border-left: none !important;
    border-right: none !important;
}

/* ADMIN TABLE CSS ENDS */