/* Customizable Area Start */

.modalDialogTitles {
    padding: 0 !important;
    margin-left: 8px !important;
}

.sharelinkModalBodyWrapper {
    padding: 0 !important;
    border-top: none !important;
    border-bottom: none !important;
}

/* Customizable Area End */