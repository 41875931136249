.logobox{
    position: absolute;
    top: 95%;
    left: 70%;
}

@media(max-width: 1440px) {
    .logobox{
        top: 67vw;
    }
  }

  @media(max-width: 800px) {
    .logobox{
        top: 210vw;
        left: 45vw;
    }
  }