/* Left Div */
.news_and_events_container .headingTitle {
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 32px !important;
    line-height: 48px !important;
    color: #1C2324 !important;
}

.news_and_events_container .breadcrumbMain {
    margin-top: 8px;
}

.news_and_events_container .menuLinkUser {
    font-style: italic !important;
    font-weight: 500 !important;
    font-size: 16px !important;
    line-height: 24px !important;
    color: #006A77 !important;
    text-decoration-line: none !important;
    opacity: 0.64 !important;
}

.filterApplied span {
    background: #DF5200;
    right: 3px;
    top: 6px;
}

.news_and_events_container .menuTextUser {
    font-style: italic !important;
    font-weight: 500 !important;
    font-size: 16px !important;
    line-height: 24px !important;
    color: #006A77 !important;
    text-decoration-line: none !important
}

.news_and_events_container .userListWrapper {
    width: 344px;
    border-radius: 12px;
    background-color: #F8F6F1;
    /* padding: 16px; */
}

.news_and_events_container .userListTitleWrapper {
    padding: 16px 16px 0px 16px !important;
}

.news_and_events_container .userListTitle {
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 18px !important;
    line-height: 28px !important;
    color: #1C2324 !important;
}

.news_and_events_container .searchInputWrapper {
    margin: 12px 0px !important;
    padding: 0px 8px 0px 16px !important;
}

.news_and_events_container .MuiListItem-root.Mui-selected,
.news_and_events_container .userlistIteam.MuiListItem-root.Mui-selected {
    background-color: #EEE9DD !important;
}

.news_and_events_container .MuiListItem-root.Mui-selected,
.news_and_events_container .userlistIteam.MuiListItem-root:hover {
    background-color: #EEE9DD !important;
}

.news_and_events_container .userlistIteam .MuiListItem-root {
    background-color: #EEE9DD !important;
}

.news_and_events_container .userListFont {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #1C2324;
}

.news_and_events_container .listWrapper {
    height: 70vh;
    overflow-y: scroll;
}

/* Right Div */
.news_and_events_container .farmDiaryloaderWrapper {
    margin-left: 24px;
    background: #F8F6F1;
    box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.06);
    border-radius: 12px;
    min-width: 712px;
    width: 100%;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}

.news_and_events_container .userFarmDiaryDetailMain {
    margin-left: 24px;
    background: #F8F6F1;
    box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.06);
    border-radius: 12px;
    min-width: 712px;
    width: 100%;
}

.news_and_events_container .usernameTitleWrapper {
    background: #F8F6F1;
    box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.06);
    height: 84px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.news_and_events_container .avtarWrapper {
    padding: 18px 16px 18px 24px !important;
}

.news_and_events_container .userAvtarCustom {
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 20px !important;
    line-height: 36px !important;
    text-align: right !important;
    color: #FFFFFF !important;
    background-color: #108B88 !important;
    width: 48px !important;
    height: 48px !important;

}

.news_and_events_container .usernameFontWrapper {
    padding: 16px 0px;
}

.news_and_events_container .userNameTitleFont {
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 18px !important;
    line-height: 28px !important;
}

.news_and_events_container .userNameSubTitleFont {
    font-style: italic !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 22px !important;
    color: #1C2324 !important;
    opacity: 0.72 !important;
}

.news_and_events_container .avtarTitleWrapper {
    display: flex;
    flex-direction: row;
}

.news_and_events_container .farmDiaryUpdateBtnWrapper {
    padding: 22px 24px;
}

.news_and_events_container .orangeUpdateBtn {
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 14px !important;
    line-height: 22px !important;
    text-align: center !important;
    text-transform: uppercase !important;
    color: #fff !important;
    background-color: #DF5200 !important;
    display: flex !important;
    align-items: center !important;
    border-radius: 28px !important;
    padding: 9px 24px !important;
    box-shadow: none !important;
}

.news_and_events_container .farmDiaryPhasesWrapper {
    height: 90vh;
    overflow-y: scroll;
}

.news_and_events_container.phaseTitleWrapper {
    display: flex;
    justify-content: space-between;
}

.news_and_events_container .phaseIconWrapper {
    padding: 16px;
}

.news_and_events_container .addEntryBtn {
    background: #F0EBE0 !important;
    border: 1px solid #E4DDC9 !important;
    border-radius: 28px !important;
    box-shadow: none !important;
    font-style: normal;
    font-weight: 500 !important;
    font-size: 14px !important;
    line-height: 22px !important;
    text-align: center;
    text-transform: uppercase !important;
    color: #1C2324 !important;
    opacity: 0.48;
    padding: 9px 32px !important;
    height: 40px !important;
}

.news_and_events_container .phaseTitlefont {
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 16px !important;
    line-height: 24px !important;
    color: #1C2324 !important;
}

.news_and_events_container.deletePhaseBtnWrapper {
    display: flex;
    align-content: center;
}

.news_and_events_container .hr-custom {
    background-color: #F0EBE0 !important;
}

.news_and_events_container .addAnotherEntryWrapper {
    display: flex;
    justify-content: end;
    margin-top: 16px !important;
}

.news_and_events_container .selectInputPhaseCustom {
    border-radius: 12px !important;
    border: 1px solid #F0EBE0 !important;
}

.news_and_events_container .phasesLabelCustom {
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 12px !important;
    line-height: 18px !important;
    color: #1C2324 !important;
    opacity: 0.64 !important;
    margin-bottom: 4px !important;
}


.news_and_events_container .inputIconTextFieldCustom .MuiInputBase-root .MuiOutlinedInput-input {
    padding: 16px 16px 16px 0px !important;
}

.news_and_events_container .inputIconTextFieldCustom .MuiOutlinedInput-adornedStart {
    padding-left: 18px !important;
}

.news_and_events_container .custom-padding {
    padding: 16px 4px 16px 16px !important;
}

.news_and_events_container .selectDDCustom {
    border-radius: 12px !important;
}

.news_and_events_container .selectDDCustom .MuiSelect-icon {
    display: none !important;
}

.news_and_events_container .selectDDCustom .MuiSelect-root {
    /* background-image: url('./../assets/DropDownArrowBigIcon.svg'); */
    background-size: 24px;
    background-repeat: no-repeat;
    background-position: calc(100% - 10px) center;
}

.news_and_events_container .phaseDateInpuCustom .MuiOutlinedInput-adornedEnd {
    padding-right: 20px !important;
}

/* Latest css */
.news_and_events_container iframe {
    pointer-events: none;
}

.news_and_events_container .mainConatiner {
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.news_and_events_container .MuiOutlinedInput-input {
    padding: 16px !important;
}

.news_and_events_container .tableSearchInput>.MuiOutlinedInput-root {
    border-radius: 28px !important;
}

.news_and_events_container .MuiOutlinedInput-root {
    border: #F0EBE0 !important;
    border-radius: 12px !important;
    background-color: #fcfaf7 !important;
}

.all-checkbox {
    margin: 0px !important;
}

.all-checkbox span:first-child{
    margin-left: 23px;
}

.news_and_events_container ::placeholder {
    color: #1c2324 !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 24px !important;
}

.news_and_events_container .userSelectionMenu>.MuiSelect-selectMenu {
    font-size: 24px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: 36px !important;
    color: #1C2324 !important;
}

.news_and_events_container .userSelectionMenu>.MuiSelect-select:focus {
    background-color: transparent !important;
}

.news_and_events_container .customColHeader {
    text-transform: uppercase !important;
    color: #1c2324 !important;
    opacity: 0.48 !important;
    font-size: 12px !important;
}

.news_and_events_container .customColHeaderUpdated {
    text-transform: uppercase !important;
    color: #1c2324 !important;
    opacity: 0.48 !important;
    font-size: 12px !important;
}

.news_and_events_container .MuiDataGrid-root .MuiDataGrid-columnHeaderTitle {
    font-weight: 600 !important;
}

.news_and_events_container .customColHeaderUpdated .MuiDataGrid-columnHeaderTitle {
    white-space: break-spaces !important;
    text-align: center;
    line-height: 20px;
}

.news_and_events_container .customRowFont {
    font-size: 14px !important;
    color: #1C2324 !important;
    line-height: 22px !important;
    font-weight: 400 !important;
    font-style: normal !important;
}

.news_and_events_container .customRowFontName {
    font-size: 14px !important;
    color: #1C2324 !important;
    line-height: 22px !important;
    font-weight: 400 !important;
    font-style: normal !important;
    padding-left: 14px;
}

.news_and_events_container .MuiDataGrid-root .MuiDataGrid-columnSeparator {
    display: none !important;
}

.news_and_events_container .customLabelField {
    font-style: normal;
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 22px !important;
    color: #1c2324 !important;
    opacity: 0.64;
}

.news_and_events_container .customContentCenter {
    display: flex;
    align-items: center;
    text-transform: uppercase;
    font-style: normal;
    font-weight: 600 !important;
    font-size: 20px !important;
    line-height: 30px !important;
}

.news_and_events_container .centerDivContent {
    display: flex;
    align-items: center !important;
    justify-content: center;
}

.news_and_events_container .centerDivContentTotal {
    display: flex;
    align-items: center !important;
    justify-content: center !important;
}

.news_and_events_container .centerDivContentTotalNew {
    display: flex;
    align-items: center !important;
}

.news_and_events_container .customImgFarmDiary {
    width: 106.32px;
    height: 95.47px;
}

.news_and_events_container .customImgFilledSurveys {
    width: 96px;
    height: 80.01px;
}


.news_and_events_container .maincard {
    background: #F8F6F1;
    border-radius: 12px;
    /* max-height: 106px; */
    min-height: 106px;
    min-width: 344px;
    /* width: 100%; */
    padding: 0px !important;
}

.news_and_events_container .subMaincard {
    padding: 20px !important;
}

.news_and_events_container .setContentLine {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.news_and_events_container .leftSubdiv {
    margin: 4px !important;
}

.news_and_events_container .subTitleCard {
    color: #1C2324;
    opacity: 0.64;
    font-style: normal;
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 24px !important;
}

.news_and_events_container .mainTitleCard {
    margin-top: 4px !important;
    font-style: normal;
    font-weight: 600 !important;
    font-size: 20px !important;
    line-height: 30px !important;
    text-transform: uppercase;
    color: #1C2324;
}

.news_and_events_container .fontBlue {
    position: relative;
}

.news_and_events_container .fontBlue::after {
    content: ' ';
    width: 2px;
    position: absolute;
    top: 0px;
    bottom: 0px;
    background:
        linear-gradient(180deg, rgba(235, 230, 215, 0.29) 0%, #EBE6D7 49.79%, rgba(235, 230, 215, 0.31) 100%)
}

.news_and_events_container .cottonAreaTxt {
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 28px !important;
    line-height: 42px !important;
    color: #006A77 !important;
    display: flex !important;
    align-items: center;
    justify-content: center;
    padding: 12px 12px 12px 32px;
}

.news_and_events_container .accordionDetails>.MuiOutlinedInput-root {
    background: #FFFFFF !important;
    border: 1px solid #F0EBE0 !important;
    border-radius: 6px !important;
}

@media only screen and (max-width: 600px) {
    .search-chat .link-share-search-input {
        width: 90% !important; 
    }
    .news_and_events_container .news-event-title {
        font-size: 16px !important;
        line-height: 18px !important;
    }
}

@media only screen and (max-width: 1550px) {
    .cottonAreaTxt {
        justify-content: center;
        text-align: center;
        padding: 0 0 0 20px;
    }
}



.news_and_events_container .MuiPickersBasePicker-container>.MuiPickersToolbar-toolbar {
    background-color: #df5200 !important;
}

.news_and_events_container .MuiPickersDay-daySelected {
    background-color: #df5200 !important;
}

.news_and_events_container .mt-8 {
    margin-top: 8px !important;
}

.news_and_events_container .customAutoComplete>.MuiFormControl-root>.MuiInputBase-root {
    border-radius: 12px !important;
    background: #FCFAF7;
}

.news_and_events_container .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
    padding: 0px !important;
}

.news_and_events_container .temp-checkbox .MuiTypography-root {
    font-size: 14px !important;
    line-height: 22px !important;
}

.news_and_events_container .temp-checkbox .MuiCheckbox-root {
    color: #F0EBE0 !important;
    margin-left: 35px;
}

.news_and_events_container .temp-checkbox .MuiFormControlLabel-label {
    color: #1C2324 !important;
    transform: translateX(-14px);
}

.news_and_events_container .temp-checkbox .MuiCheckbox-colorSecondary.Mui-checked {
    color: #DF5200 !important;
}

.news_and_events_container .filterInputName {
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 22px !important;
    color: #1C2324 !important;
}

.news_and_events_container .filterMenuDropdown {
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 12px !important;
    line-height: 18px !important;
    color: #1C2324 !important;
}

.news_and_events_container .filtermenuDropdownDiv .MuiSelect-outlined.MuiSelect-outlined {
    font-size: 12px !important;
    font-weight: 400 !important;
    line-height: 18px !important;
    color: #1C2324 !important;
    font-style: normal !important;
}

.news_and_events_container .selectInputCustom {
    border-radius: 8px !important;
}

.news_and_events_container .filterMenuTitleDiv {
    padding-right: 0px !important;
}

.news_and_events_container .filtermenuDropdownDiv {
    padding-left: 0px !important;
    padding-right: 16px !important;
}

.news_and_events_container .MuiFormHelperText-contained {
    margin: 0px !important;
}

.news_and_events_container .MuiFormHelperText-root.Mui-error {
    background: white !important;
}

.news_and_events_container .text-danger {
    color: #f44336;
    margin: 0;
    font-size: 0.75rem;
    margin-top: 3px;
    text-align: left;
    font-weight: 400;
    line-height: 1.66;
}

.news_and_events_container .filterModalTitle {
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 16px !important;
    color: #1C2324 !important;
}

.news_and_events_container .customSubTitleCards {
    padding: 32px !important;
}

@media only screen and (max-width: 1460px) {
    .news_and_events_container .customSubTitleCards {
        padding: 32px 32px 32px 0px !important;
    }
}

/* width */
::-webkit-scrollbar {
    width: 6px;
    height: 4px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 8px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.custom-mobile-input.Mui-error {
    border: 1px solid red !important
}

::-webkit-scrollbar-thumb:horizontal {
    background: #c2c2c2;
    border-radius: 10px;
}

.news_and_events_container .MuiDataGrid-columnSeparator {
    display: 'none' !important;
}

.news_and_events_container input[id^="village-textfield"]::placeholder {
    font-weight: 400 !important;
    font-size: 10px !important;
    line-height: 16px !important;
    opacity: 0.32 !important;
    color: #1C2324 !important;
}

.news_and_events_container input[id^="village-textfield"] {
    padding: 4px 0px !important;
    background: #FFFFFF !important;
}

.news_and_events_container input[id^="district-textfield"]::placeholder {
    font-weight: 400 !important;
    font-size: 10px !important;
    line-height: 16px !important;
    opacity: 0.32 !important;
    color: #1C2324 !important;
}

.news_and_events_container input[id^="district-textfield"] {
    padding: 4px 0px !important;
    background: #FFFFFF !important;
}

.news_and_events_container input[id^="taluka-textfield"]::placeholder {
    font-weight: 400 !important;
    font-size: 10px !important;
    line-height: 16px !important;
    opacity: 0.32 !important;
    color: #1C2324 !important;
}

.news_and_events_container input[id^="taluka-textfield"] {
    padding: 4px 0px !important;
    background: #FFFFFF !important;
}

.news_and_events_container input[id^="state-textfield"]::placeholder {
    font-weight: 400 !important;
    font-size: 10px !important;
    line-height: 16px !important;
    opacity: 0.32 !important;
    color: #1C2324 !important;
}



.news_and_events_container input[id^="state-textfield"] {
    padding: 4px 0px !important;
    background: #FFFFFF !important;
}

.news_and_events_container input[id^="fieldExective-textfield"] {
    padding: 4px 0px !important;
    background: #FFFFFF !important;
}

.news_and_events_container input[id^="fieldExective-textfield"]::placeholder {
    font-weight: 400 !important;
    font-size: 10px !important;
    line-height: 16px !important;
    opacity: 0.32 !important;
    color: #1C2324 !important;
}

input[id^="farmer-textfield"]::placeholder {
    font-weight: 400 !important;
    font-size: 10px !important;
    line-height: 16px !important;
    opacity: 0.32 !important;
    color: #1C2324 !important;
}

input[id^="farmer-textfield"] {
    padding: 4px 0px !important;
    background: #FFFFFF !important;
    padding-top: 0.5px !important;
}

input[id^="group-textfield"]::placeholder {
    font-weight: 400 !important;
    font-size: 10px !important;
    line-height: 16px !important;
    opacity: 0.32 !important;
    color: #1C2324 !important;
}

input[id^="group-textfield"] {
    padding: 4px 0px !important;
    background: #FFFFFF !important;
    padding-top: 0.5px !important;
}

.news_and_events_container .PrivateSwitchBase-input-10 {
    background-color: #888 !important;
}

.news_and_events_container .filterPopover .MuiPopover-paper {
    border-radius: 12px !important;
    overflow: initial;
}

.news_and_events_container .centerContentTxt {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.news_and_events_container .tableGirdDiv .MuiDataGrid-root {
    border-radius: 0px !important;
    border-left: none !important;
    border-right: none !important;
}

.news_and_events_container .tableSearchInputfilter>.MuiInputAdornment-positionStart {
    margin: 0 12px 0 15px !important;
}

.news_and_events_container .MuiMenu-paper .MuiListItem-root.MuiListItem-button.Mui-selected {
    background: none !important;
    font-weight: 500 !important;
    font-size: 16px !important;
    color: #1C2324 !important;
    opacity: 1 !important;
}

.news_and_events_container .MuiMenu-paper .MuiListItem-root.MuiListItem-button {
    background: none !important;
    font-weight: 400 !important;
    font-size: 16px !important;
    color: #1C2324 !important;
    opacity: 0.72 !important;
}

.news_and_events_container input[id^="searchTableId"]::placeholder {
    font-style: normal !important;
    font-weight: 450 !important;
    font-size: 16px !important;
    line-height: 24px !important;
    color: #1C2324 !important;
    opacity: 0.48 !important;
}

.news_and_events_container .MuiAccordionSummary-root {
    padding: 0px 12px !important;
}

.news_and_events_container .MuiAccordionSummary-expandIcon {
    padding-left: 0px !important;
}

.news_and_events_container .MuiAccordionSummary-expandIcon.Mui-expanded {
    /* transform: none !important; */
    transform: scaleY(-1) !important;
}

.news_and_events_container .userSelectionMenu .MuiSelect-icon {
    display: none !important;
}

.news_and_events_container .userSelectionMenu .MuiSelect-root {
    /* background-image: url('./../assets/ArrowDown.png'); */
    background-size: 20px;
    background-repeat: no-repeat;
    background-position: calc(100% - 5px) center;
}

.news_and_events_container .userSelectionMenu .MuiSelect-root~.MuiSelect-iconOpen {
    /* background-image: url('./../assets/ArrowUp.png'); */
}

.news_and_events_container .userSelectionMenu .MuiSelect-select.MuiSelect-select {
    padding-right: 30px !important;
}

.checkbox-container>.MuiFormControlLabel-root{
    transform: translateX(-15px);
    display: flex;
    gap: 50px;
}
.news_and_events_container .checkboxImgCustom {
    height: 24px !important;
    width: 24px !important;
}

.news_and_events_container .selectInputCustom .MuiSelect-icon {
    display: none !important;
}

.news_and_events_container .selectInputCustom .MuiSelect-root {
    /* background-image: url('./../assets/ArrowDown.png'); */
    background-size: 15px;
    background-repeat: no-repeat;
    background-position: calc(100% - 5px) center;
}

.news_and_events_container .fb-500 {
    font-weight: 500 !important;
}

.news_and_events_container .opcity-48 {
    opacity: 0.48 !important;
    color: #1C2324 !important;
}

.news_and_events_container .state-field-class>div,
.news_and_events_container .district-field-class>div,
.news_and_events_container .taluka-field-class>div,
.news_and_events_container .village-field-class>div,
.news_and_events_container .fieldExecutive-field-class>div,
.group-field-class>div,
.farmer-field-class>div
{
    background-color: #FFFFFF !important;
    border-radius: 6px !important;
}

.news_and_events_container .state-field-class>div:focus,
.news_and_events_container .district-field-class>div:focus,
.news_and_events_container .taluka-field-class>div:focus,
.news_and_events_container .village-field-class>div:focus,
.news_and_events_container .fieldExecutive-field-class>div:focus,
.group-field-class>div:focus,
.farmer-field-class>div:focus
{
    border: 2px solid #F0EBE0 !important;
    border-radius: 6px !important;
}

.news_and_events_container .state-field-class>div:hover,
.news_and_events_container .district-field-class>div:hover,
.news_and_events_container .taluka-field-class>div:hover,
.news_and_events_container .village-field-class>div:hover,
.news_and_events_container .fieldExecutive-field-class>div:hover,
.group-field-class>div:hover,
.farmer-field-class>div:hover
{
    border-radius: 6px !important;
}

/* Latest CSS end */
.news_and_events_container #outlined-multiline-static-1 {
    display: none;
}

.news_and_events_container #image-textfield-id {
    width: 100%;
    height: 200px;
    background-color: #FCFAF7 !important;
    border: 1px solid #d6d7d0;
    border-radius: 12px;
}

.news_and_events_container #image-textfield-id .MuiInputBase-root {
    padding: 0px !important;
    background-color: #FCFAF7 !important;
    border: 0px transparent !important;
}

.news_and_events_container #image-textfield-id span {
    padding: 0px !important;
}

.news_and_events_container #image-textfield-id .MuiOutlinedInput-root {
    border: none !important;
    border-radius: 0px !important;
}

.news_and_events_container #image-textfield-id>.MuiFormControl-root {
    transform: translateY(79px) !important;
    width: 100%;
    display: flex;
    align-items: center;
}

.news_and_events_container #image-textfield-id .MuiIconButton-colorPrimary {
    color: gray !important;
}

.news_and_events_container .camera-icon-class {
    font-size: 60px !important;
}

.news_and_events_container .ant-tabs-class .MuiTab-wrapper {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
}

.news_and_events_container .sendto-textfield .MuiOutlinedInput-inputAdornedEnd {
    padding-left: 0px !important;
}

.news_and_events_container .sendto-textfield .MuiIconButton-edgeStart {
    padding-right: 0px !important;
}

.news_and_events_container .date-textfield-class .MuiSvgIcon-root {
    color: rgba(0, 0, 0, 0.54);
    color: #1C2324;
    opacity: 0.3;
}

.news_and_events_container .customDatePicker svg {
    color: #1C2324;
    opacity: 0.3;
}

.customDatePicker {
    padding-top: 0px !important
}

/* .map-class  */
.map-class .MuiDialog-paperWidthSm
{
    max-width: 100vw;
    width: 100vw;
    height:100vh;
} 
.map-dialog-title{
    display: flex;
    justify-content: space-between;
    padding:10px;
    align-items: center;
}
.filter-divider-container{
    display: flex;
    justify-content: center;
}
.filter-divider{
    width: 80%;
    height: 1px;
    background: #F0EBE0;
    border-radius: 4px;
}

.map-adornment-class{
    margin-right: 7px !important;
}

.news_and_events_container .description-news-textfield-class .MuiOutlinedInput-input{
   padding: 14px !important;
}
.customDatePicker .MuiIconButton-root{
   padding: 0px;
}
.password-textfield-class .MuiIconButton-label{
    transform: rotateX(180deg);
}
.table-select-menuitem svg{
    margin-right: 10px !important;
}
.accordion-grid .MuiPaper-root {
    box-shadow: none !important;
}
