.mainContainerFarmDiaryWrapper {
    display: flex;
    flex-direction: row;
}

/* Left Div */
.headingTitle {
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 32px !important;
    line-height: 48px !important;
    color: #1C2324 !important;
}

.selectDDCustom .MuiSelect-select:focus {
    background-color: transparent !important;
}

.breadcrumbMain {
    margin-top: 0px;
}

.breadcrumbMain .MuiBreadcrumbs-separator {
    margin: 0px 4px !important;
}

.menuLinkUser {
    font-style: italic !important;
    font-weight: 500 !important;
    font-size: 16px !important;
    line-height: 24px !important;
    color: #006A77 !important;
    text-decoration-line: none !important;
    opacity: 0.64 !important;
}

.menuTextUser {
    font-style: italic !important;
    font-weight: 500 !important;
    font-size: 16px !important;
    line-height: 24px !important;
    color: #006A77 !important;
    text-decoration-line: none !important
}

.userListWrapper {
    border-radius: 12px;
    background-color: #F8F6F1;
}

.userListTitleWrapper {
    padding: 16px 16px 0px 16px !important;
}

.userListTitle {
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 18px !important;
    line-height: 28px !important;
    color: #1C2324 !important;
}

.searchInputWrapper {
    margin: 12px 0px !important;
    padding: 0px 8px 0px 16px !important;
}

.listWrapper .userlistIteam .MuiListItem-root.Mui-selected {
    background-color: #EEE9DD !important;
}

.listWrapper .userlistIteam .MuiListItem-root:hover {
    background-color: #EEE9DD !important;
}

.listWrapper .userlistIteam .MuiListItem-root {
    background-color: #EEE9DD !important;
}

.userListFont {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #1C2324;
}

.listWrapper {
    height: 912px;
    overflow-y: scroll;
}

/* Right Div */
.farmDiaryloader {
    margin-left: 24px;
}

.farmDiaryloaderWrapper {
    background: #F8F6F1;
    border-radius: 12px;
    height: 125vh;
    width: 100%;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}
.farmDiaryloaderWrapperText{
    color: #1C2324 !important;
    font-size: 32px !important;
    font-weight: 500 !important;
}

.userFarmDiaryDetailMain {
    margin-left: 24px;
    background: #F8F6F1;
    border-radius: 12px;
    height: 100%;
}

.usernameTitleWrapper {
    background: #F8F6F1;
    box-shadow: 0px 1px 12px rgba(0, 0, 0, 0.06);
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    height: 84px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.avtarWrapper {
    padding: 18px 16px 18px 24px !important;
}

.userAvtarCustom {
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 20px !important;
    line-height: 36px !important;
    text-align: right !important;
    color: #FFFFFF !important;
    background-color: #108B88 !important;
    width: 48px !important;
    height: 48px !important;

}

.usernameFontWrapper {
    padding: 16px 0px;
}

.userNameTitleFont {
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 18px !important;
    line-height: 28px !important;
}

.userNameSubTitleFont {
    font-style: italic !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 22px !important;
    color: #1C2324 !important;
    opacity: 0.72 !important;
}

.avtarTitleWrapper {
    display: flex;
    flex-direction: row;
}

.farmDiaryUpdateBtnWrapper {
    padding: 22px 24px;
}

.orangeUpdateBtn {
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 14px !important;
    line-height: 22px !important;
    text-align: center !important;
    text-transform: uppercase !important;
    color: #fff !important;
    background-color: #DF5200 !important;
    display: flex !important;
    align-items: center !important;
    border-radius: 28px !important;
    padding: 9px 24px !important;
    box-shadow: none !important;
}

.farmDiaryPhasesWrapper {
    height: 991px;
    overflow-y: scroll;
}

.phaseTitleWrapper {
    display: flex;
    justify-content: space-between;
}

.phaseIconWrapper {
    padding: 16px;
}

.addEntryBtn {
    background: #F0EBE0 !important;
    border: 1px solid #E4DDC9 !important;
    border-radius: 28px !important;
    box-shadow: none !important;
    font-style: normal;
    font-weight: 500 !important;
    font-size: 14px !important;
    line-height: 22px !important;
    text-align: center;
    text-transform: uppercase !important;
    color: #1C2324 !important;
    opacity: 0.48;
    padding: 9px 32px !important;
    height: 40px !important;
}

.phaseTitlefont {
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 16px !important;
    line-height: 24px !important;
    color: #1C2324 !important;
}

.deletePhaseBtnWrapper {
    display: flex;
    margin: 0 4px !important;
}

.hr-custom {
    background-color: #F0EBE0 !important;
}

.newAddEntry {
    display: flex;
    justify-content: end;
    margin: 0px 12px 0px 12px !important
}

.newRemove {
    margin-right: -12px !important;
    transform: translateY(0px) !important;
}

.removeEntryWrapper {
    display: flex;
    justify-content: end;
    margin-top: 0px !important;
}

.selectInputPhaseCustom {
    border-radius: 12px !important;
    border: 1px solid #F0EBE0 !important;
}

.phasesLabelCustom {
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 12px !important;
    line-height: 18px !important;
    color: #1C2324 !important;
    opacity: 0.64 !important;
    margin-bottom: 4px !important;
}


.inputIconTextFieldCustom .MuiOutlinedInput-adornedStart {
    padding-left: 10px !important;
}

.selectDDCustom {
    border-radius: 12px !important;
}

.selectDDCustom .MuiSelect-icon {
    display: none !important;
}

.selectDDCustom .MuiSelect-root {
    background-image: url('./../assets/DropDownArrowBigIcon.svg');
    background-size: 24px;
    background-repeat: no-repeat;
    background-position: calc(100% - 10px) center;
}

/* Latest css */
iframe {
    pointer-events: none;
}

.mainConatiner {
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.tableSearchInput>.MuiOutlinedInput-root {
    border-radius: 28px !important;
}

.MuiOutlinedInput-root {
    border: #F0EBE0 !important;
    border-radius: 12px !important;
    background-color: #fcfaf7 !important;
}

::placeholder {
    color: #1c2324 !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 24px !important;
}

.userSelectionMenu>.MuiSelect-selectMenu {
    font-size: 24px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: 36px !important;
    color: #1C2324 !important;
}

.userSelectionMenu>.MuiSelect-select:focus {
    background-color: transparent !important;
}

.customColHeader {
    text-transform: uppercase !important;
    color: #1c2324 !important;
    opacity: 0.48 !important;
    font-size: 12px !important;
}

.MuiDataGrid-root .MuiDataGrid-columnHeaderTitle {
    font-weight: 600 !important;
}

.customRowFont {
    font-size: 14px !important;
    color: #1C2324 !important;
    line-height: 22px !important;
    font-weight: 400 !important;
    font-style: normal !important;
}

.customRowFontName {
    font-size: 14px !important;
    color: #1C2324 !important;
    line-height: 22px !important;
    font-weight: 400 !important;
    font-style: normal !important;
    padding-left: 14px;
}

.MuiDataGrid-root .MuiDataGrid-columnSeparator {
    display: none !important;
}

.customLabelField {
    font-style: normal;
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 22px !important;
    color: #1c2324 !important;
    opacity: 0.64;
}

.customContentCenter {
    display: flex;
    align-items: center;
    text-transform: uppercase;
    font-style: normal;
    font-weight: 600 !important;
    font-size: 20px !important;
    line-height: 30px !important;
}

.centerDivContent {
    display: flex;
    align-items: center !important;
    justify-content: center;
}

.centerDivContentTotal {
    display: flex;
    align-items: center !important;
    justify-content: center !important;
}

.centerDivContentTotalNew {
    display: flex;
    align-items: center !important;
}

.customImgFarmDiary {
    width: 106.32px;
    height: 95.47px;
}

.customImgFilledSurveys {
    width: 96px;
    height: 80.01px;
}

.subMaincard {
    padding: 20px !important;
}

.setContentLine {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.leftSubdiv {
    margin: 4px !important;
}

.subTitleCard {
    color: #1C2324;
    opacity: 0.64;
    font-style: normal;
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 24px !important;
}

.mainTitleCard {
    margin-top: 4px !important;
    font-style: normal;
    font-weight: 600 !important;
    font-size: 20px !important;
    line-height: 30px !important;
    text-transform: uppercase;
    color: #1C2324;
}

.fontBlue {
    position: relative;
}

.fontBlue::after {
    content: ' ';
    width: 2px;
    position: absolute;
    top: 0px;
    bottom: 0px;
    background:
        linear-gradient(180deg, rgba(235, 230, 215, 0.29) 0%, #EBE6D7 49.79%, rgba(235, 230, 215, 0.31) 100%)
}

.cottonAreaTxt {
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 28px !important;
    line-height: 42px !important;
    color: #006A77 !important;
    display: flex !important;
    align-items: center;
    justify-content: center;
    padding: 12px 12px 12px 32px;
}

.accordionDetails>.MuiOutlinedInput-root {
    background: #FFFFFF !important;
    border: 1px solid #F0EBE0 !important;
    border-radius: 6px !important;
}

@media only screen and (max-width: 1550px) {
    .cottonAreaTxt {
        justify-content: center;
        text-align: center;
        padding: 0 0 0 20px;
    }
}

.customDatePicker {
    padding-top: 8px !important;
}

.MuiPickersBasePicker-container>.MuiPickersToolbar-toolbar {
    background-color: #df5200 !important;
}

.MuiPickersDay-daySelected {
    background-color: #df5200 !important;
}

.customAutoComplete>.MuiFormControl-root>.MuiInputBase-root {
    border-radius: 12px !important;
    background: #FCFAF7;
}

.MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
    padding: 0px !important;
}

.temp-checkbox .MuiTypography-root {
    font-size: 14px !important;
    line-height: 22px !important;
}


.temp-checkbox .MuiCheckbox-root {
    color: #F0EBE0 !important;
}

.temp-checkbox .MuiFormControlLabel-label {
    color: #1C2324 !important;
}

.temp-checkbox .MuiCheckbox-colorSecondary.Mui-checked {
    color: #DF5200 !important;
}

.filterInputName {
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 22px !important;
    color: #1C2324 !important;
}

.filterMenuDropdown {
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 12px !important;
    line-height: 18px !important;
    color: #1C2324 !important;
}

.filtermenuDropdownDiv .MuiSelect-outlined.MuiSelect-outlined {
    font-size: 12px !important;
    font-weight: 400 !important;
    line-height: 18px !important;
    color: #1C2324 !important;
    font-style: normal !important;
}

.filterMenuTitleDiv {
    padding-right: 0px !important;
}

.filtermenuDropdownDiv {
    padding-left: 0px !important;
    padding-right: 16px !important;
}

.MuiFormHelperText-contained {
    margin: 0px !important;
}

.text-danger {
    color: #f44336;
    margin: 0;
    font-size: 0.75rem;
    margin-top: 3px;
    text-align: left;
    font-weight: 400;
    line-height: 1.66;
}

.filterModalTitle {
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 16px !important;
    color: #1C2324 !important;
}

.customSubTitleCards {
    padding: 32px !important;
}

.accordion-grid .MuiPaper-root {
    box-shadow: none !important;
}

.error-text-irrigation-box {
    text-align: center;
    padding: 0 24px;
}

.error-text-irrigation-heading,
.error-text-irrigation-paragraph {
    font-style: normal;
    font-weight: 400;
    color: #1C2324;
}

.error-text-irrigation-heading {

    font-size: 16px;
    line-height: 24px;

}

.mappingImageWrapper {
    height: 145px;
    background: #FCFAF7;
    border: 1px solid #c1bfbf;
    border-radius: 12px;
    padding: 13px;
}

.mappingImageWrapper p {
    color: #1c2324 !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 24px !important;
    opacity: 0.48 !important;
}

.mapping_image {
    max-width: 25px;
    height: 28.33px;
    transform: translateY(10px);
}

.error-text-irrigation-paragraph {
    margin-top: 8px;
    font-size: 14px;
    line-height: 22px;
    opacity: 0.72;
}


@media only screen and (max-width: 1460px) {
    .customSubTitleCards {
        padding: 32px 32px 32px 0px !important;
    }
}

.inputIconTextFieldCustom .MuiInputBase-root .MuiOutlinedInput-input {
    padding: 16px 16px 16px 0px !important;
}

.inputIconTextFieldCustom .MuiInputAdornment-positionStart {
    margin-right: 1px !important;
}

.custom-padding {
    padding: 16px 16px 16px 16px !important;
}

.selectDDCustom .MuiSelect-select {
    border-radius: 12px !important;
}

.selectDDCustom .Mui-focused .MuiSelect-root~.MuiSelect-iconOpen {
    background-image: url('./../assets/ArrowUp.png');
}

.phaseDateInpuCustom .MuiOutlinedInput-adornedEnd {
    padding-right: 20px !important;
}

@media only screen and (max-width: 1470px) {
    .phaseDateInpuCustom .MuiOutlinedInput-adornedEnd {
        padding-right: 10px !important;
    }
}

.phaseDateInpuCustom .MuiInputBase-root .MuiOutlinedInput-input {
    padding: 16px 0 16px 16px !important;
}

.phaseDateInpuCustom .MuiFormHelperText-root {
    color: #f44336 !important;
}

.custom-mobile-input.Mui-error {
    border: 1px solid red !important
}

::-webkit-scrollbar-thumb:horizontal {
    background: #c2c2c2;
    border-radius: 10px;
}

.MuiDataGrid-columnSeparator {
    display: 'none' !important;
}

input[id^="village-textfield"]::placeholder {
    font-weight: 400 !important;
    font-size: 10px !important;
    line-height: 16px !important;
    opacity: 0.32 !important;
    color: #1C2324 !important;
}

input[id^="village-textfield"] {
    padding: 4px 0px !important;
    background: #FFFFFF !important;
    padding-top: 0.5px !important;
}

input[id^="district-textfield"]::placeholder {
    font-weight: 400 !important;
    font-size: 10px !important;
    line-height: 16px !important;
    opacity: 0.32 !important;
    color: #1C2324 !important;
}

input[id^="district-textfield"] {
    padding: 4px 0px !important;
    background: #FFFFFF !important;
    padding-top: 0.5px !important;
}

input[id^="taluka-textfield"]::placeholder {
    font-weight: 400 !important;
    font-size: 10px !important;
    line-height: 16px !important;
    opacity: 0.32 !important;
    color: #1C2324 !important;
}

input[id^="taluka-textfield"] {
    padding: 4px 0px !important;
    background: #FFFFFF !important;
    padding-top: 0.5px !important;
}

input[id^="state-textfield"]::placeholder {
    font-weight: 400 !important;
    font-size: 10px !important;
    line-height: 16px !important;
    opacity: 0.32 !important;
    color: #1C2324 !important;
}

input[id^="state-textfield"] {
    padding: 4px 0px !important;
    padding-top: 0.5px !important;
    background: #FFFFFF !important;
}

input[id^="fieldExective-textfield"] {
    padding: 4px 0px !important;
    background: #FFFFFF !important;
    padding-top: 0.5px !important;
}

input[id^="fieldExective-textfield"]::placeholder {
    font-weight: 400 !important;
    font-size: 10px !important;
    line-height: 16px !important;
    opacity: 0.32 !important;
    color: #1C2324 !important;
}

.PrivateSwitchBase-input-10 {
    background-color: #888 !important;
}

.filterPopover .MuiPopover-paper {
    border-radius: 12px !important;
    overflow: initial;
}

.centerContentTxt {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.tableGirdDiv .MuiDataGrid-root {
    border-radius: 0px !important;
    border-left: none !important;
    border-right: none !important;
}

.tableSearchInputfilter>.MuiInputAdornment-positionStart {
    margin: 0 12px 0 15px !important;
}

.MuiMenu-paper .MuiListItem-root.MuiListItem-button.Mui-selected {
    background: none !important;
    font-weight: 500 !important;
    font-size: 16px !important;
    color: #1C2324 !important;
    opacity: 1 !important;
}

.MuiMenu-paper .MuiListItem-root.MuiListItem-button {
    background: none !important;
    font-weight: 400 !important;
    font-size: 16px !important;
    color: #1C2324 !important;
    opacity: 0.72 !important;
}

input[id^="searchTableId"]::placeholder {
    font-style: normal !important;
    font-weight: 450 !important;
    font-size: 16px !important;
    line-height: 24px !important;
    color: #1C2324 !important;
    opacity: 0.48 !important;
}

.MuiAccordionSummary-root {
    padding: 0px 12px !important;
}

.MuiAccordionSummary-expandIcon {
    padding-left: 0px !important;
}

.MuiAccordionSummary-expandIcon.Mui-expanded {
    transform: scaleY(-1) !important;
    opacity: 1;
}

.userSelectionMenu .MuiSelect-icon {
    display: none !important;
}

.userSelectionMenu .MuiSelect-root {
    background-image: url('./../assets/ArrowDown.png');
    background-size: 20px;
    background-repeat: no-repeat;
    background-position: calc(100% - 5px) center;
}

.userSelectionMenu .MuiSelect-root~.MuiSelect-iconOpen {
    background-image: url('./../assets/ArrowUp.png');
}

.userSelectionMenu .MuiSelect-select.MuiSelect-select {
    padding-right: 30px !important;
}

.checkbox-container>.MuiFormControlLabel-root {
    margin-right: 7px !important;
}

.checkboxImgCustom {
    height: 24px !important;
    width: 24px !important;
}


.fb-500 {
    font-weight: 500 !important;
}

.opcity-48 {
    opacity: 0.48 !important;
    color: #1C2324 !important;
}

.state-field-class>div,
.district-field-class>div,
.taluka-field-class>div,
.village-field-class>div,
.fieldExecutive-field-class>div {
    background-color: #FFFFFF !important;
    border-radius: 6px !important;
}

.state-field-class>div:focus,
.district-field-class>div:focus,
.taluka-field-class>div:focus,
.village-field-class>div:focus,
.fieldExecutive-field-class>div:focus {
    border: 2px solid #F0EBE0 !important;
    border-radius: 6px !important;
}

.state-field-class>div:hover,
.district-field-class>div:hover,
.taluka-field-class>div:hover,
.village-field-class>div:hover,
.fieldExecutive-field-class>div:hover {
    border-radius: 6px !important;
}

/* Latest CSS end */

.radioLabelFontWrapper {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    margin: 0 16px 0 0px !important;
}

.checkboxLabelFont {
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 22px !important;
    color: #1C2324 !important;
    opacity: 0.64 !important;
}

.removeEntryBtnWrapper {
    display: flex !important;
    justify-content: end !important;
    align-items: center !important;
}

.loaderBlack {
    color: #1C2324 !important;
}

.phaseDateInpuCustom .MuiInputBase-root .MuiInputAdornment-root .MuiIconButton-root {
    padding: 0px !important;
}

input[name^="dateOfSowing"]::placeholder {
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 24px !important;
    color: #1C2324 !important;
    opacity: 0.48 !important;
}

.customDatePickerIcon {
    color: #1C2324 !important;
    opacity: 0.48 !important;
    height: 20px !important;
    width: 20px !important;
}

.userListFarmDiaryMain {
    flex: 0 0 344px;
    max-width: 344px
}

.diaryWrapper {
    flex: 0 0 calc(100% - 344px);
    max-width: calc(100% - 344px)
}

.text-field-main-container {
    display: "flex";
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 8px;
    white-space: nowrap;
    align-items: center;
}

.filterPopover-farm-diaries .checkbox-container>.MuiFormControlLabel-root {
    grid-gap: 0px !important;
    gap: 0px !important;
}

.text-field-container {
    display: flex !important;
    flex-direction: column !important;
    min-height: 93px !important;
}

.lineMargin {
    /* margin-top: 8px; */
    margin-top: 0px;
}

.linePadding {
    padding-left: 24px !important;
    padding-right: 24px !important;
}

.removeLinePadding {
    padding-left: 24px !important;
    padding-right: 12px;
}


.cropImageClass {
    max-width: 100%;
    display: block;
}

@media only screen and (max-width: 1440px) {
    .userListFarmDiaryMain {
        flex: 0 0 280px;
    }

    .diaryWrapper {
        flex: 0 0 calc(100% - 280px);
        max-width: calc(100% - 280px)
    }
}

@media only screen and (max-width: 1360px) {
    .mainContainerFarmDiaryWrapper {
        flex-wrap: wrap;
    }

    .userListFarmDiaryMain {
        width: 100%;
    }

    .listWrapper {
        height: 50vh;
    }
    .farmDiaryloaderWrapper{
        height: 50vh;
    }

    .farmDiaryloaderWrapperText{
        font-size: 22px !important;
    }

    .farmDiaryloader {
        margin-left: 0px;
        margin-top: 24px !important;
    }

    .userFarmDiaryDetailMain {
        margin-top: 24px !important;
        margin-left: 0px !important;
    }

    .lineMargin {
        margin-top: 0px;
        /* margin-top: 10px; */
    }

    .removeLinePadding {
        padding-right: 24px;
    }

    .newAddEntry {
        margin: 0px 24px 0px 24px !important
    }

    .newRemove {
        margin-right: 0px !important;
    }

}

@media only screen and (max-width: 1279px) {
    .text-field-main-container {
        flex-wrap: wrap !important;
    }

    .mediaRemove {
        justify-content: end !important;
    }
}

@media only screen and (max-width: 1360px) {
    .userListFarmDiaryMain {
        flex: 0 0 100%;
        max-width: 100%
    }

    .diaryWrapper {
        flex: 0 0 100%;
        max-width: 100%
    }
}

/* Global */
.d-flex {
    display: flex;
    justify-content: start;
}

.fd-row {
    flex-direction: row !important;
}

.flex-direction-row {
    flex-direction: row !important;
    justify-content: flex-start !important;
}

.ai-center {
    align-items: center !important;
    align-content: center !important;
}

.jc-space-between {
    justify-content: space-between !important;
}

.jc-center {
    justify-content: center !important;
    align-items: center !important;
}

.mt-4 {
    margin-top: 4px !important;
}

.mt-8 {
    margin-top: 8px !important;
}

.mt-12 {
    margin-top: 12px !important;
}

.mt-16 {
    margin-top: 16px !important;
}

.mt-24 {
    margin-top: 24px !important;
}

.mb-12 {
    margin-bottom: 12px !important;
}

.mb-16 {
    margin-bottom: 16px !important;
}

.pd-8 {
    padding: 8px !important;
}

.pd-16 {
    padding: 16px !important;
}

.pr-12 {
    padding-right: 12px !important;
}

.ps-16 {
    padding: 0 24px !important;
}

.pl-24 {
    padding-left: 24px !important;
}

.pr-24 {
    padding-right: 24px !important;
}

.b-400 {
    font-weight: 400 !important;
}

.b-600 {
    font-weight: 600 !important;
}

.w-100 {
    width: 100% !important;
}

.MuiFormHelperText-root.Mui-error {
    background: transparent !important;
}

/* Scroller */
/* width */
::-webkit-scrollbar {
    width: 6px;
    height: 4px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 8px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

/* NEW */
.full-divider {
    height: 2px !important;
    width: 100% !important;
    margin-top: 24px !important;
    background: #F0EBE0 !important;
}

.small-divider {
    height: 2px !important;
    width: 100% !important;
    margin-top: 0px !important;
    background: #F0EBE0 !important;
}


.remove-icon {
    display: flex;
    height: 100%;
    align-items: center;
    transform: translate(0px, 10px);
}

.mainContainerFarmDiaryWrapper .MuiInputBase-input.Mui-disabled {
    color: black;
}

.customRemoveEntryclass {
    display: flex;
}

.submit_accordion_btn {
    box-shadow: none !important;
}

.filterPopover-farm-diaries .MuiPopover-paper {
    box-shadow: 2px 4px 16px rgba(0, 0, 0, 0.12);
}

.text-field-container-mapping {
    transform: translateY(-10px);
}

@media only screen and (max-width: 750px) {

    .customRemoveEntryclass {
        display: flex;
        justify-content: end !important;
    }

    .remove-icon {
        justify-content: center;
    }
}

@media only screen and (max-width: 550px) {
    .farmDiaryUpdateBtnWrapper {
        padding: 22px 10px;
    }
    .avtarWrapper {
        padding: 18px 12px 18px 12px !important;
    }
    .wrapTextWidth {
        max-width: 160px;
        font-size: 16px;
    }
}

@media only screen and (max-width: 400px) {
    .wrapTextWidth {
        max-width: 100px;
        font-size: 16px;
    }
}
