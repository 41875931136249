.autocomplete-input {
    /* padding: 10px 20px 10px 30px; */
    width: 100%;
    border: 1px solid #f0ebe0;
    border-radius: 8px;
    box-sizing: border-box;
    position: relative;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    outline: none;
    color: #1c2324;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
}

.autocomplete-input .MuiOutlinedInput-input {
    padding: 10.5px 6px 10.5px 0px !important;
}

.autocomplete-input .MuiOutlinedInput-adornedStart {
    padding-left: 12px !important;
}

.autocomplete-input .MuiOutlinedInput-adornedEnd {
    padding-right: 12px !important;
}

.autocomplete-dropdown-container {
    border: 1px solid grey;
    border-top: none;
    /* to avoid double border */
    width: 100%;
}

.round-icon {
    padding: 4px;
    background-color: rgba(240,235,224, 0.24);
    border-radius: 6px;
}

.autocomplete-dropdown-item {
    padding: 8px 12px;
    cursor: pointer;
}

.autocomplete-dropdown-item:hover {
    background-color: #f5f5f5;
}

.autocomplete-icon-left,
.autocomplete-icon-right {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
    /* make it non-clickable */
}

.autocomplete-icon-left {
    left: 10px;
}

.autocomplete-icon-right {
    right: 10px;
}

.map-overlay {
    position: absolute;
    top: 20px;
    /* adjust as necessary */
    right: 20px;
    /* adjust as necessary */
    z-index: 1000;
    /* to ensure it's above the map layers */
    text-align: right;
    width: 246px;
}

.suggestions-dropdown {
    width: 300px;
    /* This should match your input width */
    max-height: 250px;
    overflow-y: auto;
    background-color: #fff;
    margin: 10px 0 0 0;
    text-align: start;
}

.suggestion-item {
    padding: 10px;
    cursor: pointer;
    color: #1c2324;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
}

.search-input {
    position: relative;
    border: none;
    border-radius: 8px;
    width: 246px;
    height: 40px;
    outline: none;
}
.farmMappingSubmitButton{
    margin: 16px 0px 36px 0px !important;
}