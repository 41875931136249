.breadcrumbMain {
    margin-top: 0px;
}

.breadcrumbMain .MuiBreadcrumbs-separator {
    margin: 0px 4px !important;
}
.tableUserTitle{
    margin-top: 4px !important;
    font-style: normal;
    font-weight: 600 !important;
    font-size: 20px !important;
    line-height: 30px !important;
    color: #1C2324;
}

.menuLinkUser {
    font-style: italic !important;
    font-weight: 500 !important;
    font-size: 16px !important;
    line-height: 24px !important;
    color: #006A77 !important;
    text-decoration-line: none !important;
    opacity: 0.64 !important;
}

.menuTextUser {
    font-style: italic !important;
    font-weight: 500 !important;
    font-size: 16px !important;
    line-height: 24px !important;
    color: #006A77 !important;
    text-decoration-line: none !important
}

.infocard {
    background: #F8F6F1;
    border-radius: 12px;
    /* max-height: 106px; */
    min-height: 78px;
    /* min-width: 215px; */
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 24px;
}

.infocardContent {
    width: 100%;
    padding-left: 24px;
}

.profileName {
    max-width: 170px;
    margin-top: 10px;
}

@media only screen and (max-width: 1460px) {

    .infocard {
        margin-bottom: 0px;
    }

    .profileName {
        max-width: 100%;
    }

}

.subTitleInfoCard {
    color: #1C2324;
    opacity: 0.64;
    font-style: normal;
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 22px !important;
}

.mainTitleInfoCard {
    margin-top: 4px !important;
    font-style: normal;
    font-weight: 600 !important;
    font-size: 16px !important;
    line-height: 24px !important;
    text-transform: uppercase;
    color: #1C2324;
}

.leftSubdiv {
    margin: 4px !important;
}

.countTxtInfoCard{
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 24px !important;
    line-height: 36px !important;
    color: #006A77 !important;
    display: flex !important;
    align-items: center;
    justify-content: center;
    padding: 6px 16px 6px 12px;
    min-width: 32px;
}

.expandArrowInfoCard{
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 24px !important;
    line-height: 36px !important;
    color: #1C2324 !important;
    display: flex !important;
    align-items: center;
    justify-content: center;
    padding: 6px 16px 6px 12px;
    min-width: 32px;
}
.sub-div-row-wrapper{
    max-width: calc(50% + 12px) !important;
    margin: 0 -12px 0 !important;
    flex:0 0 calc(50% + 12px) !important;
    flex-basis:calc(50% + 12px) !important;
}
.sub-div-column{
    padding: 0 12px;
    margin-bottom: 24px !important; 
}
.profile-card-wrapper{
    max-width:calc(100% + 6px) !important;
}
.profile-card-left-wrapper {
    padding: 0 12px 0 3px !important;
}

@media (max-width: 1280px){
    .sub-div-row-wrapper{
        max-width: calc(100% + 24px) !important;
        flex:0 0 calc(100% + 24px) !important;
        flex-basis:calc(100% + 24px) !important;
        margin: 0 -12px 0 !important;
    }   
    .profile-card-left-wrapper {
        margin-bottom: 24px !important;
        padding: 0 !important;
    }
    .profile-card-wrapper{        
        padding-left: 6px !important;
    }
}