* {
    box-sizing: border-box;
}

.chatview-container {
    max-width: 100%;
    margin: 0 auto;
    height: 100%;
    margin-top: 1.5rem;
    margin-right: 1.5rem;
    min-height: 90vh;
}

.chatview {
    font-family:"IBM Plex Sans", "Roboto", "Helvetica", "Arial", sans-serif;
    width: 100%;
    height: 100%;
}

.chatview .sidebar {
    max-width: 35%;
    width: 100%;
    position: relative
}

.chatview .chatview-inner {
    max-width: 65%;
    width: 100%;
}

.chat {
    display: flex;
    align-content: center;
    justify-content: space-between;
    padding-bottom: 18px;
    gap: 10px;
}

.chat-btn-wrapper {
    display: flex;
    align-content: center;
    justify-content: space-between;
    gap: 10px;
    margin-top: 16px;
}

#search-input-modal form {
    margin-bottom: 0;
}

.chat h5 {
    font-size: 32px;
    font-weight: 600;
    Line-height: 48px;
    color: #1C2324;
}

.chat-btn-wrapper button {
    border-radius: 24px;
    padding: 4px 19px;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    box-shadow: none;
    text-transform: uppercase;
    width: 100%;
}

.chat-btn-wrapper button.btn-primary {
    background: #DF5200;
    color: #fff;
    border: 1px solid #DF5200;
}

.chat-btn-wrapper button.btn-secondary {
    background: transparent;
    color: #DF5200;
    border: 1px solid #DF5200;
}

.search-chat .MuiFormControl-root {
    border: 0;
    border-radius: 0;
}

.search-chat .search-input {
    background: #FCFAF7;
    border: 1px solid #F0EBE0;
    border-radius: 28px;
    outline: none;
    padding: 14px 20px;
    line-height: 1;
}

.search-chat .MuiFormControl-root .MuiOutlinedInput-notchedOutline {
    border: 0;
    display: none;
}

.search-chat .MuiInputLabel-root {
    left: 10px;
    font-size: 15px;
}

.search-chat {
    margin-bottom: 18px;
    display: flex;
}

.chatview .chat-container {
    box-shadow: none;
    height: 100%;
}

.chatview .user-box {
    background: #F8F6F1;
    border-radius: 12px;
    padding: 11px;
    margin-bottom: 12px;
    border: 1px solid #F8F6F1;
}

.temp-checkbox .MuiTypography-root span.sorting {
    font-weight: 600;

}

.chatview .user-box.active {
    border-color: #DF5200;
}

.chatview .user-box .user-image img {
    min-width: 48px;
    max-width: 48px;
    height: 48px;
    object-fit: cover;
    border-radius: 100%;
}

.chatview .user-box .user-image .MuiAvatar-root {
    width: 48px;
    height: 48px;

}
.sidebar-chat .user-box:nth-child(3n+1) .user-symbol {
    background-color: #6A7D31;
}

.sidebar-chat .user-box:nth-child(3n+2) .user-symbol {
    background-color: #108B88;
}

.sidebar-chat .user-box:nth-child(3n+3) .user-symbol {
    background-color: #EB8F06;
}

.user-box .user-symbol {
    margin-right: 10px;
    width: 48px;
    height: 48px;
}

.user-box .user-symbol .username {
    width: 46px;
    height: 46px;
    line-height: 48px;
    font-size: 17px;
}

.chatside-user {
    display: flex;
    align-items: baseline;
    width: 100%;
    justify-content: space-between;
}

.chatside-user .name h5 {
    margin: 0;
    font-weight: 500;
    font-size: 16px;
    color: #1C2324;
    line-height: 24px;
    word-break: break-all;
}

.chatside-user .name p {
    margin: 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    color: #1C2324;
    opacity: 0.72;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    word-break: break-all;
}

.chatside-user .time {
    text-align: right;
}

.chatside-user .time span {
    font-size: 12px;
    line-height: 18px;
    font-weight: 400;
    text-align: right;
    color: #1C2324;
    opacity: 0.48;
    display: block;
    padding-bottom: 8px;
}

.chatside-user .time label {
    background: #DF5200;
    min-width: 18px;
    height: 18px;
    text-align: center;
    line-height: 18px;
    color: #fff;
    font-weight: 500;
    font-size: 12px;
    border-radius: 100%;
    display: inline-block;
}

.filterSearchHeading h6 {
    margin: 0;
    font-family:"IBM Plex Sans", "Roboto", "Helvetica", "Arial", sans-serif;
    font-size: 16px;
    color: #1C2324;
    font-weight: 500;
}

.filterSearchHeading button {
    border-radius: 0;
    border: 0;
    background: transparent;
    padding: 0;
    color: #1C2324;
    min-width: auto;
}

.checkRow .MuiIconButton-root {
    padding: 0;
    margin-right: 5px;
}

.checkRow .MuiFormControlLabel-label {
    font-size: 14px;
}

.checkRow .MuiFormControlLabel-root {
    margin: 0;
}

.selectCol {
    margin-bottom: 12px;
}

.selectCol label {
    font-weight: 400;
    font-size: 14px;
    color: #1C2324;
    font-family:"IBM Plex Sans", "Roboto", "Helvetica", "Arial", sans-serif;
    width: 25%;
    display: inline-block;
}

.selectCol .select {
    background: #FCFAF7;
    border: 1px solid #F0EBE0;
    border-radius: 8px;
    width: calc(100% - 25%);
    padding: 5px;
    display: inline-block;
    position: relative;
}

.selectCol .select select {
    background: transparent;
    border: 0;
    width: 100%;
    appearance: none;
}

.selectCol .select:after {
    content: '';
    position: absolute;
    width: 10px;
    height: 10px;
    right: 13px;
    transform: translateY(-50%) rotate(180deg);
    top: 43%;
    background-image: url("../assets/Vectordropdown.png");
    background-size: 10px;
    background-repeat: no-repeat;
}

.MuiPaper-root.MuiPopover-paper.MuiPaper-elevation8.MuiPaper-rounded {
    border-radius: 12px;
}


.sidebar-profile .profile-header {
    display: flex;
    margin-bottom: 24px;
}

.sidebar-profile .profile-header p {
    font-size: 18px;
    line-height: 28px;
    font-weight: 500;
    margin: 0;
}

.sidebar-profile .profile-arrow {
    padding: 0;
    min-width: inherit;
    margin-right: 12px;
}

.profile-img {
    height: 196px;
    width: 196px;
    margin: 0 auto;
    background: rgba(28, 35, 36, 0.16);
    align-items: center;
    display: flex;
    justify-content: center;
    text-align: center;
    border-radius: 50%;
    cursor: pointer;
}

.profile-img svg {
    width: 39px;
    height: 35px;
}

.profile-img p {
    font-size: 16px;
    line-height: 24px;
}
.chat-topbar{
    padding-inline: 24px;
}

.chat-topbar .participant-header {
    display: flex;
    margin-bottom: 24px;
}

.chat-topbar .participant-header p {
    font-style: normal;
    font-size: 18px;
    line-height: 28px;
    font-weight: 500;
    margin: 0;
}

.chat-topbar .participant-arrow {
    padding: 0;
    min-width: inherit;
    margin-right: 12px;
}

.chatview .participant-auto {
    background: #F8F6F1;
    font-size: 1rem;
    width: 100% !important;
    border: 1px solid #F0EBE0;
    border-radius: 28px !important;
    min-height: 48px;
    display: flex;

}

.chatview .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot {
    padding-right: 56px;
    padding-left: 14px;
    padding-top: 4px;
}

.chatview .MuiAutocomplete-inputRoot[class*="MuiInput-root"] .MuiAutocomplete-input:first-child {
    padding: 10px 15px;
}

.chatview .MuiInput-underline:before {
    border-bottom: none !important;
}

.chatview .MuiInput-underline:after {
    border-bottom: none !important;
}

.chatview .MuiFilledInput-root {
    background-color: #FFF !important;
}

.chatview .MuiFilledInput-underline:after {
    border-bottom: 1px solid #DF5200 !important;
}

.chatview .MuiFilledInput-underline:before {
    border-bottom: 1px solid #DF5200 !important;
}

.chatview .MuiInputLabel-filled {
    font-size: 14px !important;
    transform: translate(0px, 0px) scale(1) !important;
    line-height: 22px !important;
    opacity: 0.6399999856948853;
    color: #1C2324 !important;

}

.chatview .MuiFormLabel-root.Mui-focused {
    color: #1C2324 !important;
}

.chatview .MuiFilledInput-adornedEnd {
    padding-right: 0px !important;
}

.chatview .MuiSvgIcon-root {
    font-size: 26px !important;
}

.chatview .groupSelection .MuiAutocomplete-tag:nth-child(3n+1) .autocompleteAvatart{
    background-color: #6A7D31;
}
.chatview .groupSelection .MuiAutocomplete-tag:nth-child(3n+2) .autocompleteAvatart{
    background-color: #108B88;
}
.chatview .groupSelection .MuiAutocomplete-tag:nth-child(3n+3) .autocompleteAvatart{
    background-color: #EB8F06;
}

.chatview .groupSelection .MuiAutocomplete-tag .MuiSvgIcon-root{
    width: 18px;
    height: 18px;
}

.MuiAutocomplete-popupIndicator {
    visibility: hidden;
}

.chatlist-message-status {
    width: 18px;
    height: 18px;
}

@media (max-width: 991px) {
    /* .css-view-1dbjc4n .MuiBox-root-3 {
        grid-column: 1/span 2;
    } */

    .css-view-1dbjc4n .MuiBox-root.MuiBox-root-3 .MuiBox-root.MuiBox-root-11 {
        padding-block: 1.5rem 0 !important;
    }

    .chat-history {
        height: calc(100vh - 1.5rem);
    }
}

@media (max-width: 767px) {
    .chatview .sidebar {
        max-width: 100% !important
    }

    .chat-wrapper {
        margin-left: 0;
    }

    .chatview .chatview-inner {
        max-width: 100%  !important;
        margin-top: 24px !important;
    }

    .chat-history {
        height: 100vh;
        margin-left: 0;
    }

    .chatview .chatview-inner.open {
        display: block;
    }

    .chatview-container {
        width: 100%;
        margin-top: 1.5rem;
    }

    #message-add+form {
        margin-bottom: 0;
    }

}