iframe {
    pointer-events: none;
}

input::-ms-reveal,
input::-ms-clear {
  display: none !important;
}

.mainContainerWrapper {
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.tableSearchInput>.MuiOutlinedInput-root {
    border-radius: 28px !important;
}

.filtermenuDropdownDiv .MuiOutlinedInput-root {
    border: #F0EBE0 !important;
    border-radius: 8px !important;
    background-color: #fcfaf7 !important;
}

.filtermenuDropdownDiv .MuiSelect-select {
    border-radius: 8px !important;
    background-color: transparent !important;
}

.filtermenuDropdownDiv .MuiSelect-select:focus {
    background-color: transparent !important;
}

::placeholder {
    color: #1c2324 !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 24px !important;
}

.userSelectionMenu>.MuiSelect-selectMenu {
    font-size: 24px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: 36px !important;
    color: #1C2324 !important;
}

.userSelectionMenu>.MuiSelect-select:focus {
    background-color: transparent !important;
}

.customColHeader {
    text-transform: uppercase !important;
    color: #1c2324 !important;
    opacity: 0.48 !important;
    font-size: 12px !important;
}

.customColHeaderUpdated {
    text-transform: uppercase !important;
    color: #1c2324 !important;
    opacity: 0.48 !important;
    font-size: 12px !important;
}

.MuiDataGrid-root .MuiDataGrid-columnHeaderTitle {
    font-weight: 600 !important;
}

.customColHeaderUpdated .MuiDataGrid-columnHeaderTitle {
    white-space: break-spaces !important;
    text-align: center;
    line-height: 20px;
}

.customRowFont {
    font-size: 14px !important;
    color: #1C2324 !important;
    line-height: 22px !important;
    font-weight: 400 !important;
    font-style: normal !important;
}

.customRowFontNameFarmer {
    font-size: 14px !important;
    color: #1C2324 !important;
    line-height: 22px !important;
    font-weight: 400 !important;
    font-style: normal !important;
    padding-left: 14px;
    max-width: 130px;
}

.customRowFontName {
    font-size: 14px !important;
    color: #1C2324 !important;
    line-height: 22px !important;
    font-weight: 400 !important;
    font-style: normal !important;
    padding-left: 14px;
}

.MuiDataGrid-root .MuiDataGrid-columnSeparator {
    display: none !important;
}

.customLabelField {
    font-style: normal;
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 22px !important;
    color: #1c2324 !important;
    opacity: 0.64;
}

.customContentCenter {
    display: flex;
    align-items: center;
    text-transform: uppercase;
    font-style: normal;
    font-weight: 600 !important;
    font-size: 20px !important;
    line-height: 30px !important;
}

.centerDivContent {
    display: flex;
    align-items: center !important;
    justify-content: center;
}

.centerDivContentTotal {
    display: flex;
    align-items: center !important;
    justify-content: center !important;
}

.centerDivContentTotalNew {
    display: flex;
    align-items: center !important;
}

.customImgFarmDiary {
    width: 106.32px;
    height: 95.47px;
}

.customImgFilledSurveys {
    width: 96px;
    height: 80.01px;
}


.maincard {
    background: #F8F6F1;
    border-radius: 12px;
    /* max-height: 106px; */
    min-height: 106px;
    min-width: auto;
    /* width: 100%; */
    padding: 0px !important;
}

.subMaincard {
    padding: 20px !important;
}

.setContentLine {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.leftSubdiv {
    margin: 4px !important;
}

.subTitleCard {
    color: #1C2324;
    opacity: 0.64;
    font-style: normal;
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 24px !important;
}

.mainTitleCard {
    margin-top: 4px !important;
    font-style: normal;
    font-weight: 600 !important;
    font-size: 20px !important;
    line-height: 30px !important;
    text-transform: uppercase;
    color: #1C2324;
}

.fontBlue {
    position: relative;
}

.fontBlue::after {
    content: ' ';
    width: 2px;
    position: absolute;
    top: 0px;
    bottom: 0px;
    background:
        linear-gradient(180deg, rgba(235, 230, 215, 0.29) 0%, #EBE6D7 49.79%, rgba(235, 230, 215, 0.31) 100%)
}

.cottonAreaTxt {
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 28px !important;
    line-height: 42px !important;
    color: #006A77 !important;
    display: flex !important;
    align-items: center;
    justify-content: center;
    padding: 12px 12px 12px 32px;
}

@media only screen and (max-width: 1550px) {
    .cottonAreaTxt {
        justify-content: center;
        text-align: center;
        padding: 0 0 0 20px;
    }
}

.customDatePicker {
    padding-top: 8px !important;
}

.MuiPickersBasePicker-container>.MuiPickersToolbar-toolbar {
    background-color: #df5200 !important;
}

.MuiPickersDay-daySelected {
    background-color: #df5200 !important;
}

.mt-8 {
    margin-top: 8px !important;
}

.customAutoComplete>.MuiFormControl-root>.MuiInputBase-root {
    border-radius: 12px !important;
    background: #FCFAF7;
    height: 56px !important;
}

.customAutoComplete .MuiAutocomplete-popupIndicator .MuiIconButton-label svg {
    display: none;
}

.customAutoComplete .MuiAutocomplete-popupIndicator .MuiIconButton-label {
    height: 20px;
    width: 20px;
    transform-origin: center;
    background-image: url('./../assets/DropDownArrowBigIcon.svg');
    background-size: 20px;
    background-repeat: no-repeat !important;
}

.customAutoComplete .MuiChip-label {
    padding: 0 8px !important;
}

.customAutoComplete .MuiFormControl-root .MuiInputBase-root {
    padding: 2px 55px 2px 16px !important;
}

.MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
    padding: 0px !important;
}

.temp-checkbox .MuiTypography-root {
    font-size: 14px !important;
    line-height: 22px !important;
}

.temp-checkbox .MuiCheckbox-root {
    color: #F0EBE0 !important;
}

.temp-checkbox .MuiFormControlLabel-label {
    color: #1C2324 !important;
}

.temp-checkbox .MuiCheckbox-colorSecondary.Mui-checked {
    color: #DF5200 !important;
}

.selectInputCustom>.MuiSelect-select:focus {
    background-color: transparent !important;
}


.filterInputName {
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 22px !important;
    color: #1C2324 !important;
}

.filterMenuDropdown {
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 12px !important;
    line-height: 18px !important;
    color: #1C2324 !important;
}

.filtermenuDropdownDiv .MuiSelect-outlined.MuiSelect-outlined {
    font-size: 12px !important;
    font-weight: 400 !important;
    line-height: 18px !important;
    color: #1C2324 !important;
    font-style: normal !important;
}

.selectInputCustom {
    border-radius: 8px !important;
}

.filterMenuTitleDiv {
    padding-right: 0px !important;
}

.filtermenuDropdownDiv {
    padding-left: 0px !important;
    padding-right: 16px !important;
}

.MuiFormHelperText-contained {
    margin: 0px !important;
}

.formfieldWrapper .MuiFormHelperText-root.Mui-error {
    background-color: white !important;
}

.text-danger {
    color: #f44336;
    margin: 0;
    font-size: 0.75rem;
    margin-top: 3px;
    text-align: left;
    font-weight: 400;
    line-height: 1.66;
}

.filterModalTitle {
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 16px !important;
    color: #1C2324 !important;
}

.customSubTitleCards {
    padding: 32px !important;
}

@media only screen and (max-width: 1460px) {
    .customSubTitleCards {
        padding: 32px 32px 32px 0px !important;
    }
}

/* width */
::-webkit-scrollbar {
    width: 6px;
    height: 4px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 8px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.MuiLink-underlineHover:hover{
    text-decoration: none;
}

.custom-mobile-input.Mui-error {
    border: 1px solid red !important
}

::-webkit-scrollbar-thumb:horizontal {
    background: #c2c2c2;
    border-radius: 10px;
}

.MuiDataGrid-columnSeparator {
    display: 'none' !important;
}

.filterPopover .MuiPopover-paper {
    border-radius: 12px !important;
}

.alertPopOver.MuiPopover-paper {
    border-radius: 12px !important;
    background: #FFFFFF;
    box-shadow: 2px 8px 32px 4px rgba(0, 0, 0, 0.06);
}

.centerContentTxt {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.tableGirdDiv .MuiDataGrid-root {
    border-radius: 0px !important;
    border-left: none !important;
    border-right: none !important;
}

.tableSearchInputfilter>.MuiInputAdornment-positionStart {
    margin: 0 12px 0 15px !important;
}

.tableSearchInputfilter.searchByTopic input,
.tableSearchInputfilter.searchByTopic input:focus {
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
}

.tableSearchInputfilter.searchByTopic input::placeholder,
.tableSearchInputfilter.searchByTopic input:focus::placeholder {
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
}

.MuiMenu-paper .MuiListItem-root.MuiListItem-button.Mui-selected {
    background: none !important;
    font-weight: 500 !important;
    font-size: 16px !important;
    color: #1C2324 !important;
    opacity: 1 !important;
}

.MuiMenu-paper .MuiListItem-root.MuiListItem-button {
    background: none !important;
    font-weight: 400 !important;
    font-size: 16px !important;
    color: #1C2324 !important;
    opacity: 0.72 !important;
}

input[id^="searchTableId"]::placeholder {
    font-style: normal !important;
    font-weight: 450 !important;
    font-size: 16px !important;
    line-height: 24px !important;
    color: #1C2324 !important;
    opacity: 0.48 !important;
}

.userSelectionMenu .MuiSelect-icon {
    display: none !important;
}

.userSelectionMenu .MuiSelect-root {
    background-image: url('./../assets/ArrowDown.png');
    background-size: 20px;
    background-repeat: no-repeat;
    background-position: calc(100% - 5px) center;
}

.userSelectionMenu .MuiSelect-root~.MuiSelect-iconOpen {
    background-image: url('./../assets/ArrowUp.png');
}

.userSelectionMenu .MuiSelect-select.MuiSelect-select {
    padding-right: 30px !important;
}

.checkboxImgCustom {
    height: 24px !important;
    width: 24px !important;
}

.selectInputCustom .MuiSelect-icon {
    display: none !important;
}

.selectInputCustom .MuiSelect-root {
    background-image: url('./../assets/ArrowDown.png');
    background-size: 15px;
    background-repeat: no-repeat;
    background-position: calc(100% - 5px) center;
}

.farmerFormDDClass .MuiSelect-icon {
    display: none !important;
}

.farmerFormDDClass .MuiSelect-root {
    background-image: url('./../assets/DropDownArrowBigIcon.svg');
    background-size: 24px;
    background-repeat: no-repeat;
    background-position: calc(100% - 10px) center;
}

.fb-500 {
    font-weight: 500 !important;
}

.opcity-48 {
    opacity: 0.48 !important;
    color: #1C2324 !important;
}

.formfieldWrapper {
    height: 106px;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}

.MuiDataGrid-root .MuiDataGrid-columnHeader:focus,
.MuiDataGrid-root .MuiDataGrid-cell:focus {
    outline: none !important;
}

.MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within,
.MuiDataGrid-root .MuiDataGrid-cell:focus-within {
    outline: none !important;
}

.modalDialogTitle {
    padding: 32px 32px 24 32px !important;
}

.modalBodyWrapper {
    padding: 5px 0 0 0 !important;
    border-top: none !important;
    border-bottom: none !important;
}

.userFormBodyWrapper {
    padding: 0px 32px 32px 32px !important;
}

.userTypeFormControl {
    margin-top: 8px !important;
}

.formfieldWrapper .MuiOutlinedInput-notchedOutline {
    border-color: #F0EBE0 !important;
}

.Mui-error .MuiOutlinedInput-notchedOutline {
    border-color: red !important;
}

.MuiOutlinedInput-notchedOutline {
    border-color: #F0EBE0 !important;
}

.modalSubmitButtonWrapper {
    margin-top: 12px !important;
}
.modalSubmitFEButtonWrapper {
    margin-top: 32px !important;
}

.modalSubmitButton {
    border-radius: 28px !important;
    display: flex !important;
    align-items: center !important;
    background: #DF5200 !important;
    font-size: 18px !important;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: 28px !important;
    color: #fff !important;
    text-transform: uppercase !important;
    padding-top: 14px !important;
    padding-bottom: 14px !important;
}

.customFormSpacing {
    margin-bottom: 0px !important;
}

.userModalBodySubHeading {
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 18px !important;
    line-height: 28px !important;
    text-transform: uppercase !important;
    color: #1C2324 !important;
    margin: 4px 0 16px 0px !important;
}

.uploadTxt {
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 24px !important;
    color: #1C2324 !important;
    opacity: 0.48 !important;
}

.uploadFarmImageWrapper span {
    display: block !important;
}

.uploadFarmImageWrapper span img {
    max-height: 150px;
    object-position: center
}

.farmerFormDDClass .MuiSelect-select {
    border-radius: 12px !important;
}

.farmerFormDDClass>.MuiSelect-select:focus {
    background-color: transparent !important;
}

.genderCustomWrapper {
    display: flex;
    align-items: flex-start;
    justify-content: end;
}

.genderErrorWrapper {
    margin-left: 21px;
}

@media only screen and (max-width: 960px) {
    .genderCustomWrapper {
        justify-content: start;
    }

    .genderErrorWrapper {
        margin-left: 0;
    }
}

.genderCustomWrapper .MuiTypography-root {
    opacity: 0.64 !important;
    font-size: 16px !important;
    line-height: 24px !important;
}

.genderCustomWrapper .Mui-checked~.MuiTypography-root {
    opacity: 1 !important;
}

.filterApplied span {
    background: #DF5200;
    right: 3px;
    top: 6px;
}
.userFormBodyWrapper .MuiInputBase-input.Mui-disabled{
    color: #000;
}

/* ALERT CSS START */
.ErrorModalWrapper {
    text-align: center !important;
    padding: 72px 64px;
}

.ErrorTitleTxt {
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 24px !important;
    line-height: 36px !important;
    color: #1C2324 !important;
}

.ErrorBodyTxtWrapper {
    margin-top: 16px;
}

.ErrorBodyTxt {
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 18px !important;
    line-height: 28px !important;
    text-align: center !important;
    color: #1C2324 !important;
}

.MuiPaper-rounded:has(.ErrorModalWrapper) {
    border-radius: 12px !important;
    box-shadow: 2px 8px 32px 4px rgba(0, 0, 0, 0.06) !important;
    background-color: #fff;
}

.errorButtonsWrapper {
    display: flex;
    padding-top: 24px;
    justify-content: center;
}


.modalRedButton {
    margin: 0 20px !important;
    background: #B3261E !important;
    border-radius: 28px !important;
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 18px !important;
    line-height: 28px !important;
    text-align: center !important;
    text-transform: uppercase !important;
    color: #FFFFFF !important;
    padding: 14px 32px !important;
}

.modalGreyButton {
    margin: 0 20px !important;
    background: #E4DDC9 !important;
    border-radius: 28px !important;
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 18px !important;
    line-height: 28px !important;
    text-align: center !important;
    text-transform: uppercase !important;
    color: #1C2324 !important;
    opacity: 0.72 !important;
    padding: 14px 32px !important;
}

/* ALERT CSS END */

/* CUSTOMISED FILTER STARTS */
.accordionDetailsPadding {
    padding: 4px 8px 8px 8px !important;
    display: block !important;
}

.AccordionSummaryWrapper .MuiAccordionSummary-content {
    overflow: hidden !important;
}

.AccordionSummaryWrapper .MuiIconButton-root {
    padding: 0px !important;
    margin-right: 0px !important;
    opacity: 0.5;
}

.customDDtextfield .MuiOutlinedInput-adornedStart {
    padding-left: 6px !important;
}

.customDDtextfield .MuiInputAdornment-positionStart {
    margin-right: 4px !important;
}
.customDDtextfield .MuiOutlinedInput-root .MuiOutlinedInput-input{
    padding: 0 !important;
}

input[id^="searchDDField"]::placeholder {
    font-weight: 400 !important;
    font-size: 10px !important;
    line-height: 16px !important;
    opacity: 0.32 !important;
    color: #1C2324 !important;
}

input[id^="searchDDField"] {
    background: #FFFFFF !important;    
    color: #1C2324 !important;
    font-size: 10px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 16px !important;
    height: 20px !important;
}

/* CUSTOMISED FILTER ENDS */
@media only screen and (max-width: 1550px) {
    .MuiTablePagination-input {
        display: inline-flex !important;
    }

    .MuiTablePagination-toolbar .MuiTablePagination-caption {
        display: inline-flex !important;
    }
}

.MuiPickersYear-yearSelected{
    margin: 0px !important;    
    color: #df5200 !important; 
}