.leftBlock {
    background-color: #F8F6F1;
    border-radius: 12px;
    width: 100%;
    min-height: 78px;
    padding: 12px 24px;
}

.leftBlock.noBorderBottom {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.accordionSeparator:not(:last-child) {
    border-bottom: 2px solid #F0EBE0;
}

.accordionSeparator:last-child {
    border-bottom-left-radius: 12px !important;
    border-bottom-right-radius: 12px !important;
}

.cardLabel {
    font-family: IBM Plex Sans, Roboto, Helvetica, Arial, sans-serif !important;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    color: #1C2324;
    opacity: 0.64;
} 

.labelCardSpan {
    display: flex;
    gap: .5rem;
    flex-direction: column;
} 

.cardLabelValue {
    font-family: IBM Plex Sans, Roboto, Helvetica, Arial, sans-serif !important;
    font-size: 18px !important;
    font-weight: 600 !important;
    line-height: 28px !important;
    letter-spacing: 0em !important;
    color: #006A77 !important;
    text-transform: uppercase;
}

.MuiAccordion-root.Mui-expanded {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
}
.MuiPaper-elevation1 {
    box-shadow: none !important;
}
.MuiAccordion-root:before {
    display: none !important;
}

.farmDiaryContainer,
.userSurveyContainer {
    background-color: #f8f6f1 !important;
    font-family: IBM Plex Sans, Roboto, Helvetica, Arial, sans-serif !important;
}

.farmDiaryContainer .sectionHeading,
.userSurveyContainer .sectionHeading {
    color: #1c2324;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    text-transform: uppercase;
}

.totalFarmDiaries,
.totalUserSurvey {
    color: #006a77;
    text-align: center;
    font-family: IBM Plex Sans, Roboto, Helvetica, Arial, sans-serif !important;
    font-size: 20px;
    font-style: normal;
    font-weight: 600 !important;
    line-height: 30px;
    /* 150% */
    text-transform: uppercase;
}

.farmDiaryGrid,
.userSurveyGrid {
    max-height: 350px;
    overflow-y: auto;
}


@media only screen and (min-width: 991px) {
    .farmerViewLeftSection {
        padding-right: 15px;
    }
    .farmerViewRightSection {
        padding-left: 15px;
    }

    .farmerViewRightSectionBoxes>div:nth-child(odd) {
        padding: 0 20px 20px 0;
    }
    .farmerViewRightSectionBoxes>div:nth-child(even) {
        padding: 0 0 20px 0;
    }
}

@media only screen and (max-width: 991px) {
    .farmerViewRightSectionBoxes>div {
        padding-bottom: 20px;
    }
    .assignedFEBox {
        margin-top: 16px;
    }
}
