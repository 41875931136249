.gridBox {
  width: 100%;
  display: grid;
  grid-template-columns: 22% 78%;
}

.appBarToolbar {
  display: flex;
  padding-inline: 0;
  width: 60%;
  align-items: center
}

@media only screen and (max-width: 960px) {
  .gridBox {
    width: 100%;
    display: flex
  }

  .appBarToolbar {
    width: 100%
  }

}

@media screen and (max-width: 568px) {
  .searchInput {
    text-overflow: ellipsis !important;
    overflow: hidden !important;
  }

  .searchInputUsername {
    text-overflow: ellipsis !important;
    overflow: hidden !important;
    width: 90%;
    white-space: nowrap;
  }

}